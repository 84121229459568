import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import SimpleTextLink from '../../../../components/simple-textlink/SimpleTextLink';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Intro = styled.div`
  display: inline;

  > div {
    top: ${spacing.space4};
  }
`;

const RemovalInfo = styled.p`
  margin-bottom: ${spacing.space24};
  max-width: 637px;
`;

const Heading = styled(Text)`
  margin-top: 0;
  margin-bottom: 8px;
`;

const SubHeadingText = styled.p`
  margin-bottom: 0;
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  margin: 32px 0;
  @media (min-width: ${breakpoints.m}) {
    margin: 16px 0;
  }
`;

const LabelTextWrapper = styled.div<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: row;
  margin: 10px 10px 10px 0 !important;
  white-space: nowrap;
  gap: ${spacing.space8};
`;

const LabelIcon = styled.span<{ $visible: boolean }>`
  margin-right: 5px;
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
`;

const LabelText = styled(SimpleTextLink)`
  line-height: 3rem;
`;

const CampaignCode = styled.div<{ $visible: boolean }>`
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  flex-direction: column;
`;

const InputWrapper = styled.div<{ $isHidden?: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: row;
  margin-right: ${spacing.space16};
  > * {
    margin-right: ${spacing.space16};
  }
`;

const CampaignCodeMessageWrapper = styled.div<{ $isErrored?: boolean }>`
  color: ${({ $isErrored }) => ($isErrored ? nafColor.information.errorDark : nafColor.primary.park)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${spacing.space16};
  margin-bottom: ${spacing.space32};
  > * {
    margin-right: ${spacing.space16};
  }
`;

const CampaignCodeMessageText = styled.p`
  margin: 0 0 8px 0;
`;

const InlineLabel = styled.label`
  > div {
    box-sizing: border-box;
    max-width: 100%;
  }
`;

export default {
  ContentWrapper,
  FormWrapper,
  StyledForm,
  Intro,
  RemovalInfo,
  Heading,
  SubHeadingText,
  Label,
  LabelTextWrapper,
  LabelIcon,
  LabelText,
  CampaignCodeMessageWrapper,
  CampaignCode,
  CampaignCodeMessageText,
  InputWrapper,
  InlineLabel,
};
