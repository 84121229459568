import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { formattedPrice } from '../../../lib/formattedPrice';
import RadioButton from '../../../components/RadioButton';
import useSelector from '../../../redux/typedHooks';
import { Spinner } from '../../../components/spinner/Spinner';
import { PriceItem } from '../../../../../types/productType';
import { ProductOrderType } from '../forms/Membership';
import { ProductID } from '../../../lib/ProductID';

export const ProductSelect = ({
  updateOrder,
  value,
}: {
  updateOrder: (newProductOrder: ProductOrderType) => void;
  value: ProductOrderType;
}) => {
  const product = useSelector((state) => state.becomeAMember.data.products[value?.productId]);
  const isUpdating = useSelector((state) => state.becomeAMember.isUpdating);

  if (!product && !isUpdating)
    return (
      <Text variant={TextVariant.ArticleTextHeader}>
        Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
      </Text>
    );

  if (!product && isUpdating) return <Spinner />;

  return (
    product && (
      <RadioButtonGroup>
        {product.productAppPrice ? (
          Object.values(product.productAppPrice).map((price: PriceItem) => (
            <StyledRadioButton
              dataTestId="product-select"
              onChange={() => {
                updateOrder({
                  productId: product.productNumber as ProductID,
                  amount: price.itemCount,
                  isHouseholdProduct: product.isHouseholdProduct,
                });
              }}
              variant="standard"
              checked={value.amount === price.itemCount}
              outline
              key={`${product.productNumber}-${price.itemCount}`}
              label={`${price.itemCount} ${price.itemCount > 1 ? 'personer' : 'person'}`}
              message={`${formattedPrice(price.itemTotalPrice)} kr for 12 måneder`}
              width="content"
            />
          ))
        ) : (
          <Text variant={TextVariant.ArticleTextHeader}>
            Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
          </Text>
        )}
      </RadioButtonGroup>
    )
  );
};

const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 24px;
  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 8px;
  margin-bottom: 0;
  padding-right: 12px;
  min-width: 205px;
  padding-bottom: 16px;
  @media (min-width: ${breakpoints.xl}) {
    max-width: 205px;
    min-width: 179px;
  }
`;
